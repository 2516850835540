<template>
  <div>
    <div id="ulke-dropdown-container">
      <span>Ülke seçiniz: </span>
      <v-select
        v-model="selectedCountry"
        label="countryName"
        :options="ulkeList"
      />

    </div><br>
    <div id="sehir-grid-container">
      <b-form-group>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.sehirekle-modal
          class="d-flex align-items-left"
          squared
          variant="outline-primary"
          :disabled="selectedCountry.countryId == -1"
        >
          <feather-icon
            icon="AddIcon"
            class="mr-50"
          />
          <span>Şehir Ekle</span>
        </b-button>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        placeholder:'Şehir ara'}"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      theme="my-theme"
      @on-row-click="onDataGridItemClicked"
    >
      <div slot="emptystate">
        <center> Şehir kaydı bulunmuyor.</center>
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <template>
              <b-button-group
                class="mt-25"
                size="sm"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  class="mr-1"
                  squared
                  variant="outline-warning"
                  @click="onUpdateCityBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Düzenle</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  squared
                  variant="outline-danger"
                  @click="onDeleteCityBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Sil</span>
                </b-button>
              </b-button-group>
            </template>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- Şehir ekle modal -->
    <b-modal
      id="sehirekle-modal"
      cancel-variant="outline-secondary"
      ok-title="Ekle"
      cancel-title="İptal"
      centered
      no-close-on-backdrop
      title="Şehir Ekle"
      @ok="onModalSaveCity"
      @cancel="onModalCancelled"
    >
      <b-form>
        <b-form-group>
          <label for="cityName">Şehir Adı:</label>
          <b-form-input
            id="cityName"
            v-model="newCityName"
            type="text"
            placeholder="Şehir Adı"
            :state="newCityName.length > 0"
          />
          <b-form-invalid-feedback>
            Şehir adı boş bırakılamaz.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <label for="plateCode">Plaka Kodu:</label>
          <b-form-input
            id="plateCode"
            v-model="newPlateCode"
            type="text"
            placeholder="Plaka kodu"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <!--  Şehir güncelle modal -->
    <b-modal
      id="sehirguncelle-modal"
      cancel-variant="outline-secondary"
      ok-title="Guncelle"
      cancel-title="İptal"
      centered
      no-close-on-backdrop
      title="Sehir Güncelle"
      @ok="onModalUpdateCity"
      @cancel="onModalCancelled"
    >
      <b-form>
        <b-form-group>
          <label for="cityId">Identity</label>
          <b-form-input
            id="cityId"
            v-model="newCityId"
            disabled
            type="text"
          />
        </b-form-group>
        <b-form-group>
          <label for="countryId">Ülke: </label>
          <v-select
            v-model="newCountry"
            label="countryName"
            :options="ulkeList"
          />
        </b-form-group>
        <b-form-group>
          <label for="cityName">Şehir:</label>
          <b-form-input
            id="cityName"
            v-model="newCityName"
            type="text"
            placeholder="Şehir Adı"
            :state="newCityName.length > 0"
          />
          <b-form-invalid-feedback>
            Şehir adı boş bırakılamaz.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <label for="plateCode">Plaka Kodu:</label>
          <b-form-input
            id="plateCode"
            v-model="newPlateCode"
            type="text"
            placeholder="Plaka kodu"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import * as apiSehir from '@/api/tanimlar/sehir'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import vSelect from 'vue-select'
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BButtonGroup, BButton, BModal, BForm, VBModal, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'SehirList',
  components: {
    vSelect,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButtonGroup,
    BButton,
    BModal,
    BForm,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      selectedCountry: { countryId: -1, countryName: 'Seçiniz' },
      newCountry: { },
      newCityId: 0,
      newCityName: '',
      newPlateCode: '',
      pageLength: 10,
      columns: [
        {
          label: 'Sehir Adı',
          field: 'cityName',
        },
        {
          label: 'Plaka',
          field: 'plateCode',
        },
        {
          label: 'İşlem',
          field: 'action',
          sortable: false,
          width: '15%',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    ...mapGetters({
      ulkeList: 'ulke/GET_COUNTRYLIST',
      ulke: 'ulke/GET_COUNTRY',
    }),
  },
  watch: {
    selectedCountry() {
      this.getCityList()
    },
  },
  created() {
    this.setCountryList([])
  },
  mounted() {
    this.getCountryList()
  },
  methods: {
    ...mapMutations({
      setCountryList: 'ulke/SET_COUNTRYLIST',
    }),
    ...mapActions({
      fetchCountryList: 'ulke/action_get_countries',
    }),
    getCountryList() {
      this.fetchCountryList()
    },
    getCityList() {
      apiSehir.getCityByCountryId(this.selectedCountry.countryId).then(res => {
        this.rows = res.data.list
      })
    },
    onDataGridItemClicked(params) {
      return params
    },
    clearModal() {
      this.newCityId = 0
      this.newCityName = ''
      this.newPlateCode = ''
    },
    onModalSaveCity() {
      if (this.newCityName.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Kayıt Eklenemedi',
            text: 'Boş kayıt eklenemez.',
            icon: 'PlusSquareIcon',
            variant: 'danger',
          },
        })
        return
      }
      const cityItem = {
        cityId: 0, countryId: this.selectedCountry.countryId, cityName: this.newCityName, plateCode: this.newPlateCode,
      }
      apiSehir.addCity(cityItem).then(() => {
        this.getCityList()
      })
      this.clearModal()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Şehir ekle',
          text: 'Yeni kayıt eklendi.',
          icon: 'PlusSquareIcon',
          variant: 'success',
        },
      })
    },
    onModalCancelled() {
      this.clearModal()
    },
    onUpdateCityBtnClicked(row) {
      this.newCountry = this.selectedCountry
      this.newCityId = row.cityId
      this.newCityName = row.cityName
      this.newPlateCode = row.plateCode
      this.$bvModal.show('sehirguncelle-modal')
    },
    onDeleteCityBtnClicked(row) {
      this.$bvModal.msgBoxConfirm(` '${row.cityName}' tanımı silinecek, onaylıyor musunuz?`, {
        title: 'Uyarı!',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Evet',
        cancelTitle: 'Hayır',
        footerClass: 'p-2',
        hideHeaderClose: true,
        noCloseOnBackdrop: true,
        centered: true,
      })
        .then(value => {
          if (value) {
            apiSehir.deleteCity(row.cityId).then(() => {
              this.getCityList()
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Kayıt Silindi',
                text: `${row.cityName} kaydı silindi.`,
                icon: 'DeleteIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    onModalUpdateCity() {
      if (this.newCityName.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Kayıt Güncellenmedi',
            text: 'Boş kayıt güncellenemez.',
            icon: 'PlusSquareIcon',
            variant: 'danger',
          },
        })
        return
      }
      const cityItem = {
        cityId: this.newCityId, countryId: this.newCountry.countryId, cityName: this.newCityName, plateCode: this.newPlateCode,
      }
      apiSehir.updateCity(cityItem).then(() => {
        this.getCityList()
      })
      this.clearModal()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Kayıt Güncellendi',
          text: 'Kayıt başarıyla güncellendi',
          icon: 'EditIcon',
          variant: 'warning',
        },
      })
    },
    sleep(ms) {
      const unixTimeMs = new Date().getTime()
      // eslint-disable-next-line no-empty
      while (new Date().getTime() < unixTimeMs + ms) {}
    },
  },
}
</script>
<style lang='scss'>
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
